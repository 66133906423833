export default {
  backToFront: function(user) {
    return {
      id: user.id,
      user_type: user.user_type,
      personal_info: {
        email: user.email,
        // password: '',
        // confirmPassword: '',
        firstname: user.first_name,
        lastname: user.last_name,
        country: user.country_id,
        state: user.state_id,
        city: user.city,
        postal: user.zipcode
      },
      student_info: {
        institution: user.institutions[0].id,
        student_id: user.student_id,
        course: user.programs[0].id,
        graduation_date: new Date(user.graduation_date),
        current_year: user.currently_in_year
      },
      proof_enrolement: {
        proof1: {
          file: null,
          proof_type: user.proofs.find(p => p.type == "P1").proof_type_id
        },
        proof2: {
          file: null,
          proof_type: user.proofs.find(p => p.type == "P2").proof_type_id
        },
        comment: user.comments === null ? "" : user.comments
      }
    };
  },
  frontToBack(user) {
    return {
      id: user.id,
      user_type: user.user_type,
      // personal_info
      email: user.personal_info.email,
      first_name: user.personal_info.firstname,
      last_name: user.personal_info.lastname,
      country_id: user.personal_info.country,
      state_id: user.personal_info.state,
      city: user.personal_info.city,
      zipcode: user.personal_info.postal,
      // student_info
      institute_id: user.student_info.institution,
      program_id: user.student_info.course,
      student_id: user.student_info.student_id,
      graduation_date: this.dateInFormat(user.student_info.graduation_date),
      currently_in_year: user.student_info.current_year,
      // proof_enrolement
      proof_2: user.proof_enrolement.proof2.file,
      proof_1: user.proof_enrolement.proof1.file,
      proof_1_type_id: user.proof_enrolement.proof1.proof_type,
      proof_2_type_id: user.proof_enrolement.proof2.proof_type,
      comments: user.proof_enrolement.comment
    };
  },
  teacherJsonBackToFront: function(user) {
    return {
      id: user.id,
      user_type: user.user_type,
      teacher_requirement: {
        email: user.email,
        // password: '',
        // confirmPassword: '',
        firstname: user.first_name,
        lastname: user.last_name,
        country: user.country_id,
        state: user.state_id,
        city: user.city,
        postal: user.zipcode
      },
      about_program: {
        institution: user.institutions[0].id,
        course: user.programs[0].id,
        job_title: user.job_title,
        class_size: user.class_size,
        proof1: {
          file: null,
          proof_type: user.proofs.find(p => p.type == "P1").proof_type_id
        },
        proof2: {
          file: null,
          proof_type: user.proofs.find(p => p.type == "P2").proof_type_id
        }
      }
    };
  },
  teacherJsonFrontToBack: function(user) {
    return {
      id: user.id,
      user_type: user.user_type,
      email: user.teacher_requirement.email,
      first_name: user.teacher_requirement.firstname,
      last_name: user.teacher_requirement.lastname,
      country_id: user.teacher_requirement.country,
      state_id: user.teacher_requirement.state,
      city: user.teacher_requirement.city,
      zipcode: user.teacher_requirement.postal,

      institute_id: user.about_program.institution,
      program_id: user.about_program.course,
      job_title: user.about_program.job_title,
      class_size: user.about_program.class_size,
      currently_in_year: user.about_program.current_year,
      proof_2: user.about_program.proof2.file,
      proof_1: user.about_program.proof1.file,
      proof_1_type_id: user.about_program.proof1.proof_type,
      proof_2_type_id: user.about_program.proof2.proof_type
    };
  },
  backToFrontOther: function(user) {
    return {
      id: user.id,
      user_type: user.user_type,
      email: user.email,
      firstname: user.first_name,
      lastname: user.last_name,
      country: user.country_id,
      state: user.state_id,
      city: user.city,
      postal: user.zipcode,
      not_qualify_reason: user.not_qualify_reason,
      hear_about: user.hear_about_WYSIWYG,
    };
  },
  frontToBackOther: function(user) {
    return {
      id: user.id,
      user_type: user.user_type,
      email: user.email,
      first_name: user.firstname,
      last_name: user.lastname,
      country_id: user.country,
      state_id: user.state,
      city: user.city,
      zipcode: user.postal,
      not_qualify_reason: user.not_qualify_reason,
      hear_about_WYSIWYG: user.hear_about,
    };
  },
  dateInFormat(date) {
    if (date) {
      if (typeof date == "string") {
        date = new Date(date);
      }
      let _selectedDateObject = date; // this.student.student_info.graduation_date
      let _month = _selectedDateObject.getMonth() + 1;
      _month = _month > 9 ? _month : "0" + _month;
      let _date = _selectedDateObject.getDate();
      _date = _date > 9 ? _date : "0" + _date;
      return _selectedDateObject.getFullYear() + "-" + _month + "-" + _date;
    }
    return date;
  }
};
